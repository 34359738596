import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Form, Input, message } from "antd";
import { galaxyKids } from "../../assets/styles/globle";
import axios from "axios";
import { useParams } from "react-router-dom";

const ContactNow = (props) => {
  const { modalOpen, setModalOpen } = props;
  const [form] = Form.useForm();
  const [teacher, setTeacher] = useState(null);
  const { id } = useParams(); // Retrieve id from URL

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    const fetchTeacher = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teacher/${id}`,
          { headers }
        );
        setTeacher(response?.data?.data);
      } catch (err) {
        console.log("🚀 ~ fetchTeacher ~ err:", err);
      }
    };

    fetchTeacher();
  }, [id]);

  const handleFormSubmit = async (values) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        from: values?.email,
        to: teacher?.email,
        name: values?.name,
        teacherName: teacher?.name,
        purpose: values?.purpose,
        message: values?.message,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher/inquiry-now`,
        data,
        {
          headers,
        }
      );

      console.log("API response:", response.data);
      form.resetFields();
      toggleModal();
      message.success("Form submitted successfully!");
    } catch (error) {
      console.error("API error:", error);
      message.error("Failed to submit the form. Please try again later.");
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        contentClassName="custom-modal-content"
        backdropClassName="custom-modal-backdrop"
      >
        <ModalHeader
          toggle={toggleModal}
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: galaxyKids.textOrange,
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          Inquire Now
        </ModalHeader>
        <ModalBody
          className="custom-modal-body"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={handleFormSubmit}
            name="basic"
            style={{
              backgroundColor: galaxyKids.backgroundColor,
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter your email" }]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item
              label="Purpose"
              name="purpose"
              rules={[{ required: true, message: "Please enter the purpose" }]}
            >
              <Input placeholder="Enter the purpose" />
            </Form.Item>

            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true, message: "Please enter your message" }]}
            >
              <Input.TextArea placeholder="Enter your message" />
            </Form.Item>
          </Form>
        </ModalBody>
        <ModalFooter
          className="custom-modal-footer"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Button
            className="border-0"
            style={{
              color: galaxyKids.textOrange,
              backgroundColor: galaxyKids.backgroundColor,
            }}
            onClick={toggleModal}
          >
            Cancel
          </Button>
          <Button
            className="border-0"
            style={{ backgroundColor: galaxyKids.textOrange, color: "#fff" }}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ContactNow;
