import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import Header from "../Header";
import Footer from "../FooterPart";
import { galaxyKids } from "../../assets/styles/globle";
import { Select, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import TutorProfile from "./TutorProfile";
import styled from "styled-components";

const { Option } = Select;

const Wrapper = styled.div`
  padding-top: 62.84px;

  @media (min-width: 768px) {
    padding-top: 76px;
  }
`;

const TutorPage = () => {
  const [formData, setFormData] = useState({
    language: "",
    location: "",
    availability: "",
    pricing: "",
    ratings: "",
    contactDetail: "",
  });
  const [tutors, setTutors] = useState([]);
  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    console.log("formData", formData);
  };

  return (
    <div
      style={{
        backgroundColor: galaxyKids.backgroundColor,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Header />

      <Wrapper>
        <div className="mt-4 text-center container">
          <h2 style={{ color: galaxyKids.textOrange, fontSize: "32px" }}>
            Learn Language with the best Tutors online here
          </h2>
        </div>
        <TutorProfile />
        <Footer />
      </Wrapper>
    </div>
  );
};

export default TutorPage;
