import axios from "axios";

export const token = localStorage.getItem("accessToken");
export const teacherId = localStorage.getItem("teacherId"); // Assuming id is the user's ID

export const TextWithParagraphs = ({ text }) => {
  // Split the text into paragraphs using double line breaks as the delimiter
  const paragraphs = text?.split(/\n\s*\n/);

  return (
    <div>
      {paragraphs?.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  );
};

export const fetchTeacher = async (setTeacher) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/teacher/${teacherId}`,
      { headers }
    );
    setTeacher(response?.data?.data);
  } catch (err) {
    console.log("🚀 ~ fetchTeacher ~ err:", err);
  }
};

