import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import Header from "./Header";
import Footer from "./FooterPart";
import { galaxyKids } from "../assets/styles/globle";
import { Select, Pagination } from "antd";
import TutorList from "./TutorList";
import axios from "axios";
import { toast } from "react-toastify";
import { useSettings } from '../context/CountryContext';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactPlayer from "react-player";
import styled from "styled-components";

const { Option } = Select;

const Wrapper = styled.div`
  padding-top: 62.84px;

  @media (min-width: 768px) {
    padding-top: 76px;
  }
`;

const TutorsScreen = () => {

    //const languageOptions = []
    const { configuredSettings } = useSettings();
    const experienceOptions = [];
    configuredSettings?.data?.experience.map((data) => {
        experienceOptions.push({ value: data, label: data });
    })
    // configuredSettings?.data?.languages.map((data) => {
    //   languageOptions.push({ value: data, label: data });
    // })
    const [loader, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [tutors, setTutors] = useState([]);
    const [metaData, setMetaData] = useState([]);
    const [limit, setLimit] = useState(15);
    //const [options, setOptions] = useState([]);
    //const [address, setAddress] = useState({});

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const handleCountryChange = (value) => {
        setSelectedCountry(value);
        fetchStates(value);
        setSelectedState(null);
        setSelectedCity(null);

        const selectedCountry = countries.find(country => `${country.id}/${country.iso2}` === value);
        const countryLabel = selectedCountry ? selectedCountry.name : null;
        handleInputChange("country", countryLabel)
    };


    const handleStateChange = (option) => {
        setSelectedState(option);
        fetchCities(option);
        setSelectedCity(null);

        const selectedState = states.find(state => `${state.id}/${state.state_code}` === option);
        const stateLabel = selectedState ? selectedState.name : null;

        handleInputChange("state", stateLabel)
    };

    const handleCityChange = (option) => {
        setSelectedCity(option);

        handleInputChange("city", option)
    };


    const fetchStates = async (countryId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/states/${countryId}`);
            setStates(response.data.data);
            setCities([]); // Clear cities when country changes
        } catch (error) {
            console.error("Error fetching states:", error);
        }
    };

    const fetchCities = async (stateId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cities/${stateId}`);
            setCities(response.data.data);
        } catch (error) {
            console.error("Error fetching cities:", error);
        }
    };

    const generateOptions = () => {
        const newOptions = [];
        //for (let i = 5; i <= 100; i += 10) {
        configuredSettings?.data?.radius.map((data) => {
            return newOptions.push(<Option key={data} value={data.toString()}>{data} km</Option>);
        })
        //}
        //setOptions(newOptions);
    };

    const [formData, setFormData] = useState({
        language: "",
        location: "",
        country: null,
        state: null,
        city: null,
        latitude: null,
        longitude: null,
        radius: null,
        availability: "",
        pricing: "",
        ratings: "",
        contactDetail: "",
        experience: [], // Add experience to formData state
    });

    //const autocompleteRef = useRef(null);
    // const { isLoaded, loadError } = useLoadScript({
    //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    //   libraries: ["places"],
    // });

    useEffect(() => {

        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/countries`);
                setCountries(response.data.data);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        fetchCountries();

        const savedFilters = JSON.parse(localStorage.getItem("tutorFilters"));
        if (savedFilters) {
            setFormData(savedFilters);
            setCurrentPage(savedFilters.page || 1);
            setLimit(savedFilters.limit || 15);
        }

        fetchTutors();

        generateOptions();
    }, []);

    useEffect(() => {
        fetchTutors();
    }, [formData, currentPage, limit]);

    const fetchTutors = async () => {
        setLoading(true);
        let isReset = false;
        const params = {
            page: currentPage,
            paginate: limit,
        };

        if (formData.latitude) {
            params.latitude = formData.latitude;
            isReset = true;
        }
        if (formData.longitude) {
            params.longitude = formData.longitude;
            isReset = true;
        }
        if (formData.language) {
            params.language = formData.language;
            isReset = true;
        }
        if (formData.radius) {
            params.radius = formData.radius;
            isReset = true;
        }
        if (formData.availability) {
            params.availability = formData.availability;
            isReset = true;
        }
        if (formData.pricing) {
            params.pricing = formData.pricing;
            isReset = true;
        }
        if (formData.ratings) {
            params.rating = formData.ratings;
            isReset = true;
        }
        if (formData.contactDetail) {
            params.contact_detail = formData.contactDetail;
            isReset = true;
        }
        if (formData.country) {
            params.country = formData.country;
            isReset = true;
        }
        if (formData.state) {
            params.state = formData.state;
            isReset = true;
        }
        if (formData.city) {
            params.city = formData.city;
            isReset = true;
        }
        if (formData.experience.length > 0) {
            params.experience = formData.experience.join(",");
            isReset = true;
        }
        if (isReset) {
            setCurrentPage(1);
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/teacher`,
                { params }
            );
            setTutors(response?.data?.data?.teachers);
            setMetaData(response?.data?.data?.metadata);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const handleInputChange = (name, value) => {
        if (name === 'radius' && formData.location === '') {
            toast.error("Please Select a location");
        } else {
            const updatedFormData = { ...formData, [name]: value };
            setFormData(updatedFormData);
            localStorage.setItem("tutorFilters", JSON.stringify(updatedFormData));
        }
    };

    const resetFilters = () => {
        setFormData({
            language: "",
            location: "",
            country: null,
            state: null,
            city: null,
            latitude: null,
            longitude: null,
            radius: null,
            availability: "",
            pricing: "",
            ratings: "",
            contactDetail: "",
            experience: [], // Reset experience filter
        });
        setCurrentPage(1); // Reset to first page
        setSelectedCountry(null); // Reset selected country
        setSelectedState(null); // Reset selected state
        setSelectedCity(null); // Reset selected city
        localStorage.removeItem("tutorFilters");
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem("tutorFilters", JSON.stringify({ ...formData, page }));
    };

    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
        localStorage.setItem("tutorFilters", JSON.stringify({ ...formData, limit: pageSize }));
    };

    return (
        <div className="teacher-landing"
            style={{
                backgroundColor: galaxyKids.backgroundColor,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Header loaderProp={loader} />


            {/* { configuredSettings?.data?.banner_media && (<section className="pt-5 mt-5">
      
        <img
          src={
            //`${process.env.REACT_APP_MEDIA_URL}/user/image/` + tutor.picture
            `${process.env.REACT_APP_BASE_URL}/api/banner/${configuredSettings?.data?.banner_media}`
          }
          className="p-2"
          style={{
            width: "100%",
            height: "300px"
          }}
        />
      </section>)} */}
            {configuredSettings?.data?.banner_media && (
                <Wrapper>
                    <Carousel showThumbs={false} autoPlay infiniteLoop>
                        {configuredSettings.data.banner_media.map((banner, index) => {
                            // Check if the banner is a video URL
                            const isVideo = banner.endsWith('.mp4') || banner.includes('youtube.com') || banner.includes('vimeo.com');

                            return (
                                <div key={index}>
                                    {isVideo ? (
                                        <ReactPlayer
                                            url={`${process.env.REACT_APP_BASE_URL}/api/banner/${banner}`}
                                            className="p-2"
                                            width="100%"
                                            height="300px"
                                            controls
                                            playing={true} // Auto-play the video
                                        />
                                    ) : (
                                        <img
                                            src={`${process.env.REACT_APP_BASE_URL}/api/banner/${banner}`}
                                            className="p-2"
                                            style={{
                                                width: "100%",
                                                height: "300px"
                                            }}
                                            alt={`Banner ${index + 1}`}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </Carousel>
                </Wrapper>
            )}
            <section className="filter-section">
                <div className="container-fluid">
                    <div className="container-fluid text-center">
                        <div className="row mb-3">
                            <h2 style={{ color: galaxyKids.whiteBackground, fontSize: "32px", paddingBottom: "10px" }}>
                                Find Expert Chinese Language Tutors for Online or Local Classes
                                Free Service Provided by Quippy
                            </h2>
                            <Col md={3} className="text-center mb-3">
                                <Select
                                    placeholder="Language"
                                    size="large"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={formData.language || undefined}
                                    onChange={(value) => handleInputChange("language", value)}
                                >
                                    {configuredSettings?.data?.languages && configuredSettings?.data?.languages.map((data, index) => (
                                        <Option value={data}>{data}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col md={3} className="text-center mb-3" >
                                <Select
                                    size="large"
                                    style={{
                                        width: "100%",
                                    }}
                                    showSearch
                                    value={selectedCountry}
                                    options={countries.map(country => ({ value: `${country.id}/${country.iso2}`, label: country.name }))}
                                    placeholder="Country"
                                    onChange={handleCountryChange}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Col>
                            <Col md={3} className="text-center mb-3" >
                                <Select
                                    size="large"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={selectedState}
                                    options={states.map(state => ({ value: `${state.id}/${state.state_code}`, label: state.name }))}
                                    placeholder="State"
                                    onChange={handleStateChange}
                                    isDisabled={!selectedCountry}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Col>
                            <Col md={3} className="text-center mb-3" >
                                <Select
                                    size="large"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={selectedCity}
                                    options={cities.map(city => ({ value: city.name, label: city.name }))}
                                    placeholder="City"
                                    onChange={handleCityChange}
                                    isDisabled={!selectedState}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Col>
                            <Col md={3} className="text-center mb-3">
                                <Select
                                    placeholder="Radius"
                                    size="large"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={formData.radius || undefined}
                                    onChange={(value) => handleInputChange("radius", value)}
                                >
                                    {/* {options} */}
                                    {configuredSettings?.data?.radius && configuredSettings?.data?.radius.map((data, index) => (
                                        <Option value={data.toString()}>{data} km</Option>
                                    ))}
                                </Select>
                            </Col>
                            {/* Second Row */}
                            <Col md={3} className="text-center mb-3">
                                <Select
                                    placeholder="Availability"
                                    size="large"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={formData.availability || undefined}
                                    onChange={(value) => handleInputChange("availability", value)}
                                >
                                    {configuredSettings?.data?.availability && configuredSettings?.data?.availability.map((data, index) => (
                                        <Option value={data}>{data}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col md={3} className="text-center mb-3">
                                <Select
                                    placeholder="Price per lesson"
                                    size="large"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={formData.pricing || undefined}
                                    onChange={(value) => handleInputChange("pricing", value)}
                                >
                                    {configuredSettings?.data?.price && configuredSettings?.data?.price.map((data, index) => (
                                        <Option value={data.toString()}>{data} USD</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col md={3} className="text-center mb-3">
                                <Select
                                    placeholder="Ratings"
                                    size="large"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={formData.ratings || undefined}
                                    onChange={(value) => handleInputChange("ratings", value)}
                                >
                                    <Option value="1">1 Star</Option>
                                    <Option value="2">2 Stars</Option>
                                    <Option value="3">3 Stars</Option>
                                    <Option value="4">4 Stars</Option>
                                    <Option value="5">5 Stars</Option>
                                </Select>
                            </Col>
                            <Col md={3} className="text-center mb-3">
                                <input
                                    type="text"
                                    placeholder="Search by name or keyword"
                                    style={{
                                        width: "100%",
                                        height: "40px",
                                        padding: "0 12px",
                                        fontSize: "16px",
                                        boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.1)",
                                        border: "1px solid #d9d9d9",
                                        borderRadius: "4px",
                                    }}
                                    value={formData.contactDetail}
                                    onChange={(e) => handleInputChange("contactDetail", e.target.value)}
                                />
                            </Col>
                            <Col md={3} className="text-center mb-3">
                                {/* <Select
                    mode="multiple"
                    placeholder="Expertise"
                    size="large"
                    style={{
                      width: "100%",
                      height: "40px",
                      fontSize: "16px",
                    }}
                    value={formData.experience || undefined}
                    onChange={(value) => handleInputChange("experience", value)}
                  >
                    <Option value="Preschool">Preschool</Option>
                    <Option value="K12">K12</Option>
                    <Option value="Adult">Adult</Option>
                  </Select> */}
                                <Select
                                    style={{
                                        width: "100%",
                                        height: "40px",
                                        fontSize: "16px",
                                    }}
                                    mode="multiple"
                                    value={formData.experience || undefined}
                                    options={experienceOptions}
                                    placeholder="Expertise"
                                    //onChange={value => form.setFieldsValue({ experience: value })}
                                    onChange={(value) => handleInputChange("experience", value)}
                                />
                            </Col>
                        </div>
                        <button size="large" style={{ width: '200px', color: '#FFFF' }} onClick={resetFilters} className="btn btn-info signup-button mb-4"><strong>Reset Filter</strong></button>
                    </div>
                </div>
            </section>

            <TutorList tutors={tutors} loading={loader} />

            <div className="mt-4 d-flex justify-content-center">
                <Pagination
                    current={currentPage}
                    total={metaData?.total}
                    pageSize={limit}
                    onChange={handlePageChange}
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    pageSizeOptions={["15", "30", "45"]}
                />
            </div>

            <Footer />
        </div>
    );
};

export default TutorsScreen;
